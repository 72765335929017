import axios from 'axios';
import {OrderInputData} from "../types/Order.ts";

const API_URL = import.meta.env.VITE_API_URL;

export const fetchProducts = async () => {
    try {
        const response = await axios.get(`${API_URL}products/`, {
            headers: getHeaders()
        });

        return response.data;
    } catch (error) {
        console.error("Error fetching products:", error);
        throw error;
    }
};

export const fetchCategories = async () => {
    try {
        const response = await axios.get(`${API_URL}categories/`, {
            headers: getHeaders()
        });

        return response.data;
    } catch (error) {
        console.error("Error fetching categories:", error);
        throw error;
    }
};

export const fetchCities = async () => {
    try {
        const response = await axios.get(`${API_URL}cities/`, {
            headers: getHeaders()
        });

        return response.data;
    } catch (error) {
        console.error("Error fetching cities:", error);
        throw error;
    }
};

export const fetchPaymentMethods = async () => {
    try {
        const response = await axios.get(`${API_URL}payment-methods/`, {
            headers: getHeaders()
        });

        return response.data;
    } catch (error) {
        console.error("Error fetching categories:", error);
        throw error;
    }
};

export const placeOrder = async (data: OrderInputData) => {
    const response = await axios.post(`${API_URL}orders/`, data, {
        headers: getHeaders()
    });

    return response.data;
}

export const fetchProductsByCategory = async (categoryId: number) => {
    const response = await axios.get(`${API_URL}products/`, {
        params: { category: categoryId },
        headers: getHeaders()
    });

    return response.data;
};

export const fetchOrders = async () => {
    const response = await axios.get(`${API_URL}user/orders/`, {
        headers: getHeaders()
    });

    return response.data;
};

export const changePassword = async (data: {current_password: string, new_password: string}) => {
    const response = await axios.post(`${API_URL}user/change-password/`, data, {
        headers: getHeaders()
    });

    return response.data;
};

export const saveAddress = async (data: {
    city: number;
    street: string;
    state?: string;
    postal_code: string;
    country: string;
    delivery_time_start: string;
    delivery_time_end: string;
}) => {
    const response = await axios.post(`${API_URL}company-addresses/`, data, {
        headers: getHeaders(),
    });

    return response.data;
};

export const saveCompany = async (data: {
    name: string;
    tin: string;
    invoice_address: string;
}, id: number) => {
    const response = await axios.patch(`${API_URL}companies/${id}/`, data, {
        headers: getHeaders(),
    });

    return response.data;
};

const getHeaders = () => {
    return {
        // @ts-ignore
        Authorization: `Bearer ${JSON.parse(localStorage.getItem('token'))}`
    }
}