import {useAppDispatch, useAppSelector} from "../../hooks/redux.ts";
import {useEffect, useState} from "react";
import {loadCategories} from "../../redux/slices/categoriesSlice.ts";
import {Logo} from "../../components/common";
import {useCatalog} from "../../contexts/CatalogContext.tsx";
import {useLocation, useNavigate} from "react-router-dom";
import {IoCallOutline} from "react-icons/io5";
import {Category} from "../../types/Catalog.ts";
import {FiChevronDown, FiChevronRight} from "react-icons/fi";

const CatalogSidebar = () => {
    const { selectedCategory, setSelectedCategory } = useCatalog();
    const dispatch = useAppDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    const categories = useAppSelector((state) => state.categories.items);
    const categoriesStatus = useAppSelector((state) => state.categories.status);
    // @ts-ignore
    const telephone = JSON.parse(window.settings).telephone;
    useEffect(() => {
        if (categoriesStatus === 'idle') {
            dispatch(loadCategories());
        }
    }, [categoriesStatus, dispatch]);

    const handleCategoryClick = (categoryId: number) => {
        setSelectedCategory(categoryId)
        if (location.pathname != '/') navigate(`/`);
    }

    const buildCategoryTree = (categories: Category[]) => {
        const categoryMap = new Map();

        categories.forEach((category) => {
            categoryMap.set(category.id, { ...category, children: [] });
        });

        const tree: Category[] = [];

        categories.forEach((category) => {
            if (category.parent) {
                categoryMap.get(category.parent).children.push(categoryMap.get(category.id));
            } else {
                tree.push(categoryMap.get(category.id));
            }
        });

        return tree;
    };

    const categoryTree = buildCategoryTree(categories);

    return <div className="flex flex-col shadow-md h-full p-2 rounded-br-2xl bg-white justify-between overflow-y-auto">
        <div className="flex flex-col w-full">
            <div className="p-4">
                <Logo/>
            </div>
            <CategoryTree
                categories={categoryTree}
                onCategoryClick={handleCategoryClick}
                selectedCategory={selectedCategory}
            />
        </div>

        <div className="px-4 py-2 rounded-lg border border-gray-300">
            <a href={`tel:${telephone}`} className="flex flex-row items-center gap-x-2">
                <IoCallOutline size={16}/> {telephone}</a>
        </div>
    </div>
}

// @ts-ignore
const CategoryTree = ({ categories, onCategoryClick, selectedCategory }) => {
    const [expandedCategories, setExpandedCategories] = useState({});

    const toggleCategory = (categoryId: number) => {
        setExpandedCategories((prev) => ({
            ...prev,
            // @ts-ignore
            [categoryId]: !prev[categoryId],
        }));
    };

    return (
        <div className="flex flex-col w-full justify-center overscroll-y-auto divide-y divide-gray-200 h-fit">
            {categories.map((category: Category) => (
                <div key={category.id} className="flex flex-col">
                    <div
                        className={`flex flex-row items-center px-2 py-2 hover:bg-primary hover:text-white cursor-pointer rounded-2xl ${
                            selectedCategory === category.id ? 'bg-primary text-white' : ''
                        }`}
                        onClick={() => {
                            toggleCategory(category.id);
                            if (category.parent) {
                                onCategoryClick(category.id);
                            }
                        }}
                    >
                        {/* @ts-ignore */}
                        {category.children.length > 0 ? (
                            // @ts-ignore
                            expandedCategories[category.id] ? (
                                <FiChevronDown className="mr-2" />
                            ) : (
                                <FiChevronRight className="mr-2" />
                            )
                        ) : (
                            <span className="mr-6" />
                        )}
                        <h2>{category.name}</h2>
                    </div>
                    {/* @ts-ignore */}
                    {category.children.length > 0 && expandedCategories[category.id] && (
                        <div className="ml-4">
                            <CategoryTree
                                categories={category.children}
                                onCategoryClick={onCategoryClick}
                                selectedCategory={selectedCategory}
                            />
                        </div>
                    )}
                </div>
            ))}
        </div>
    );
};

export default CatalogSidebar