import {FC, useState} from "react";
import {AiOutlineCloseCircle} from "react-icons/ai";
import Modal from "react-modal";
import {useTranslation} from "react-i18next";
import {Formik} from "formik";
import * as Yup from "yup";
import {Button} from "../common";
import { toast } from 'react-toastify';
import {changePassword, saveAddress, saveCompany} from "../../services/apiService.ts";
import {useAuth} from "../../providers/AuthProvider.tsx";
import Select from "react-select";
import {useAppSelector} from "../../hooks/redux.ts";
import {countriesOptions} from "../../constants/defaultValues.ts";

const AccountModal: FC<{isOpen: boolean, close: (event: any) => void}> = ({isOpen, close}) => {
    const [tab, setTab] = useState(1)
    const { t } = useTranslation();

    return <Modal
        isOpen={isOpen}
        onRequestClose={close}
    >
        <div className="flex flex-row items-end justify-end pb-2">
            <div className="cursor-pointer">
                <AiOutlineCloseCircle size={20} onClick={close}/>
            </div>
        </div>
        <div className="flex flex-row p-4 h-full gap-x-2">
            <div className="w-1/3 gap-y-2 flex flex-col">
                <div
                    className={`shadow-md py-2 px-4 cursor-pointer rounded-2xl ${tab === 1 && 'bg-primary text-white'}`}
                    onClick={() => setTab(1)}
                >{t("button.company_information")}</div>
                <div
                    className={`shadow-md py-2 px-4 cursor-pointer rounded-2xl ${tab === 2 && 'bg-primary text-white'}`}
                    onClick={() => setTab(2)}
                >{t("button.addresses")}</div>
                <div
                    className={`shadow-md py-2 px-4 cursor-pointer rounded-2xl ${tab === 3 && 'bg-primary text-white'}`}
                    onClick={() => setTab(3)}
                >{t("button.change_password")}</div>
            </div>
            <div className="w-2/3">
                { tab === 1 && <CompanyInformationTab /> }
                { tab === 2 && <AddressesTab /> }
                { tab === 3 && <ChangePasswordTab /> }
            </div>
        </div>
    </Modal>
}

const CompanyInformationTab = () => {
    const ValidationSchema = Yup.object().shape({})
    const {t} = useTranslation();
    const {user, me} = useAuth()

    return <div className="flex flex-col w-full gap-y-4 px-4">
        <Formik
            initialValues={{
                name: user?.company?.name ?? '',
                tin: user?.company?.tin ?? '',
                invoice_address: user?.company?.invoice_address ?? ''
            }}
            // @ts-ignore
            onSubmit={(values) => {
                saveCompany(values, user?.company?.id!)
                    .then(() => me())
                    .then(() => toast.success(t('message.saved')))
                    .catch(() => toast.error(t("error.message.something_went_wrong")))
            }}
            validationSchema={ValidationSchema}
        >
            {({
                  values,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  errors,
                  touched
              }) => (<div className="flex flex-col w-full gap-y-4 px-4">
                <div className="flex flex-col gap-y-1">
                    <div>
                        <label
                            htmlFor="name"
                            className="block text-sm font-medium leading-6 "
                        >{t('field.name')}</label>
                    </div>

                    <input
                        id="name"
                        name="name"
                        type="text"
                        value={values.name}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        required
                        className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 px-4"/>
                    {errors.name && touched.name ? (
                        <div className="text-red text-sm">{errors.name}</div>) : null}
                </div>
                <div className="flex flex-col gap-y-1">
                    <div>
                        <label
                            htmlFor="tin"
                            className="block text-sm font-medium leading-6 "
                        >{t('field.tin')}</label>
                    </div>

                    <input
                        id="tin"
                        name="tin"
                        type="text"
                        value={values.tin}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        required
                        className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 px-4"/>
                    {errors.tin && touched.tin ? (
                        <div className="text-red text-sm">{errors.tin}</div>) : null}
                </div>
                <div className="flex flex-col gap-y-1">
                    <div>
                        <label
                            htmlFor="invoice_address"
                            className="block text-sm font-medium leading-6 "
                        >{t('field.invoice_address')}</label>
                    </div>

                    <input
                        id="invoice_address"
                        name="invoice_address"
                        type="text"
                        value={values.invoice_address}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        required
                        className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 px-4"/>
                    {errors.invoice_address && touched.invoice_address ? (
                        <div className="text-red text-sm">{errors.invoice_address}</div>) : null}
                </div>
                <div>
                    <Button title={t('button.save')} onClick={handleSubmit}/>
                </div>
            </div>)
            }
        </Formik>
    </div>
}

const AddressesTab = () => {
    const {t} = useTranslation();
    const {user, me} = useAuth()
    const [isNew, setIsNew] = useState(false)
    const {items, currentCity} = useAppSelector((state) => state.cities);

    const cityOptions = items.map((city) => ({
        value: city.id,
        label: city.name,
        city,
    }));

    const defaultCityValue = cityOptions.find((option) => option.city.id === currentCity?.id);

    if (!user) return <></>

    const AddressSchema = Yup.object().shape({
        city: Yup.object()
            .shape({
                value: Yup.string().required(t('error.validation.city_required')),
                label: Yup.string().required(),
            })
            .required(t('error.validation.city_required')),
        street: Yup.string()
            .required(t('error.validation.street_required'))
            .max(255, t('error.validation.street_255')),
        state: Yup.string()
            .nullable()
            .max(255, t('error.validation.state_255')),
        postal_code: Yup.string()
            .required(t('error.validation.postal_required'))
            .max(20, t('error.validation.postal_255')),
        country: Yup.object()
            .shape({
                value: Yup.string().required(t('error.validation.country_required')),
                label: Yup.string().required(),
            })
            .required(t('error.validation.country_required')),
        delivery_time_start: Yup.string()
            .required(t('error.validation.delivery_time_start_required'))
            .matches(
                /^([01]\d|2[0-3]):([0-5]\d)$/,
                t('error.validation.delivery_time_invalid')
            ),
        delivery_time_end: Yup.string()
            .required(t('error.validation.delivery_time_end_required'))
            .matches(
                /^([01]\d|2[0-3]):([0-5]\d)$/,
                t('error.validation.delivery_time_invalid')
            )
            .test('is-after-start', t('error.validation.delivery_time_end_after_start'), function (value) {
                const { delivery_time_start } = this.parent;
                if (!delivery_time_start || !value) return true; // Skip if either is not set
                return value > delivery_time_start; // End time must be after start time
            }),
    });

    return <div className="flex flex-col w-full gap-y-4 px-4">
        {
            !isNew && <div className="gap-y-2 flex flex-col divide-y">
                {
                    !user.company?.addresses?.length && <div className="w-full text-center">{t('label.no_addresses')}</div>
                }
                {
                    user.company?.addresses.map((address) => (
                        // @ts-ignore
                        <div className="py-1">{`${address.street}, ${items.find(city => city?.id == parseInt(address.city))?.name}, ${address.postal_code}, ${countriesOptions.find(country => country.value == address.country)?.label}`}</div>
                    ))
                }
            </div>

        }
        {
            !isNew && <div>
                <Button title={t('button.add_new_address')} onClick={() => setIsNew(true)}/>
            </div>
        }
        {
            isNew && <Formik
                initialValues={{
                    street: '',
                    postal_code: '',
                    country: countriesOptions[0],
                    city: defaultCityValue,
                    state: '',
                    delivery_time_start: '',
                    delivery_time_end: ''
                }}
                // @ts-ignore
                onSubmit={(values) => {
                    (async () => {
                        saveAddress({
                            street: values.street,
                            postal_code: values.postal_code,
                            country: values.country.value as string,
                            city: values.city?.value as number,
                            state: values.state as string | undefined,
                            delivery_time_start: values.delivery_time_start,
                            delivery_time_end: values.delivery_time_end,
                        })
                            .then(() => me())
                            .then(() => toast.success(t('message.saved')))
                            .then(() => setIsNew(false))
                            .catch(() => toast.error(t("error.message.something_went_wrong")))
                    })()
                }}
                validationSchema={AddressSchema}
            >
                {({
                      values,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      errors,
                      touched,
                      setFieldValue
                  }) => (<div className="flex flex-col w-full gap-y-4 px-4">
                    <div className="flex flex-col gap-y-1">
                        <div>
                            <label
                                htmlFor="street"
                                className="block text-sm font-medium leading-6 "
                            >{t('field.street')}</label>
                        </div>

                        <input
                            id="street"
                            name="street"
                            type="text"
                            value={values.street}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            required
                            className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 px-4"/>
                        {errors.street && touched.street ? (
                            <div className="text-red text-sm">{errors.street}</div>) : null}
                    </div>

                    <div className="flex flex-col gap-y-1">
                        <div>
                            <label
                                htmlFor="postal_code"
                                className="block text-sm font-medium leading-6 "
                            >{t('field.postal_code')}</label>
                        </div>

                        <input
                            id="postal_code"
                            name="postal_code"
                            type="text"
                            value={values.postal_code}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            required
                            className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 px-4"/>
                        {errors.postal_code && touched.postal_code ? (
                            <div className="text-red text-sm">{errors.postal_code}</div>) : null}
                    </div>

                    <div className="flex flex-col lg:flex-row gap-x-4">
                        <div className="lg:w-1/2">
                            <Select
                                id="city"
                                value={values.city}
                                onChange={option => setFieldValue("city", option)}
                                options={cityOptions}
                                onBlur={handleBlur}
                            />
                            {errors.city && touched.city ? (
                                <div className="text-red text-sm">{errors.city}</div>) : null}
                        </div>
                        <div className="lg:w-1/2">
                            <Select
                                id="country"
                                value={values.country}
                                onChange={option => setFieldValue("country", option)}
                                // @ts-ignore
                                options={countriesOptions}
                                onBlur={handleBlur}
                            />
                            {errors.country && touched.country ? (
                                // @ts-ignore
                                <div className="text-red text-sm">{errors.country}</div>) : null}
                        </div>
                    </div>

                    <div className="flex flex-col">
                        <div>
                            <label
                                htmlFor="postal_code"
                                className="block text-sm font-medium leading-6 "
                            >{t('field.delivery_time')}</label>
                            <p className="text-xs text-gray-500">{t('label.delivery_time_note')}</p>
                        </div>
                        <div className="flex lg:flex-row lg:gap-x-4">
                            <div>
                                <div>
                                    <label
                                        htmlFor="postal_code"
                                        className="block text-sm font-medium leading-6 "
                                    >{t('field.from')}</label>
                                </div>
                                <input
                                    type="time"
                                    name="delivery_time_start"
                                    placeholder="Delivery Start Time"
                                    value={values.delivery_time_start}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 px-4"
                                />
                                {errors.delivery_time_start && touched.delivery_time_start ? (
                                    <div className="text-red text-sm">{errors.delivery_time_start}</div>) : null}
                            </div>
                            <div>
                                <div>
                                    <label
                                        htmlFor="postal_code"
                                        className="block text-sm font-medium leading-6 "
                                    >{t('field.to')}</label>
                                </div>
                                <input
                                    type="time"
                                    name="delivery_time_end"
                                    placeholder="Delivery End Time"
                                    value={values.delivery_time_end}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 px-4"
                                />
                                {errors.delivery_time_end && touched.delivery_time_end ? (
                                    <div className="text-red text-sm">{errors.delivery_time_end}</div>) : null}
                            </div>
                        </div>
                    </div>

                    <div>
                        <Button title={t('button.save')} onClick={handleSubmit}/>
                    </div>
                </div>)
                }
            </Formik>
        }
    </div>
}

const ChangePasswordTab = () => {
    // @ts-ignore
    const [isLoading, setIsLoading] = useState(false)
    const {t} = useTranslation();

    const ChangePasswordSchema = Yup.object().shape({
        current_password: Yup.string()
            .required(t('error.validation.required'))
            .min(8, t('error.validation.password_too_short'))
            .matches(/[a-zA-Z]/, t('error.validation.password_latin')),
        new_password: Yup.string()
            .required(t('error.validation.required'))
            .min(8, t('error.validation.password_too_short'))
            .matches(/[a-zA-Z]/, t('error.validation.password_latin')),
        repeat_password: Yup.string()
            // @ts-ignore
            .oneOf([Yup.ref('new_password'), null], t('error.validation.passwords_do_not_match'))
            .required(t('error.validation.required')),
    });

    return <Formik
        initialValues={{new_password: '', repeat_password: '', current_password: ''}}
        onSubmit={(values) => {
            (async () => {
                setIsLoading(true)
                changePassword({current_password: values.current_password, new_password: values.new_password})
                    .then(() => toast.success(t('message.updated')))
                    .catch((err) => toast.error(err?.response?.data?.current_password[0] ? t("error.message.incorrect_current_password") : err?.message || err))
                    .finally(() => setIsLoading(false));
            })()
        }}
        validationSchema={ChangePasswordSchema}
    >
        {({
              values,
              handleChange,
              handleBlur,
              handleSubmit,
              errors,
              touched
          }) => (<div className="flex flex-col w-full gap-y-4 px-4">
            <div className="flex flex-col gap-y-1">
                <div>
                    <label
                        htmlFor="current_password"
                        className="block text-sm font-medium leading-6 "
                    >{t('field.current_password')}</label>
                </div>

                <input
                    id="current_password"
                    name="current_password"
                    type="password"
                    value={values.current_password}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    autoComplete="current-password"
                    placeholder="********"
                    required
                    className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 px-4"/>
                {errors.current_password && touched.current_password ? (
                    <div className="text-red text-sm">{errors.current_password}</div>) : null}
            </div>

            <div className="flex flex-col gap-y-1">
                <div>
                    <label
                        htmlFor="new_password"
                        className="block text-sm font-medium leading-6 "
                    >{t('field.new_password')}</label>
                </div>

                <input
                    id="new_password"
                    name="new_password"
                    type="password"
                    value={values.new_password}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    autoComplete="current-password"
                    placeholder="********"
                    required
                    className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 px-4"/>
                {errors.new_password && touched.new_password ? (
                    <div className="text-red text-sm">{errors.new_password}</div>) : null}
            </div>

            <div className="flex flex-col gap-y-1">
                <div>
                    <label
                        htmlFor="repeat_password"
                        className="block text-sm font-medium leading-6 "
                    >{t('field.repeat_password')}</label>
                </div>

                <input
                    id="repeat_password"
                    name="repeat_password"
                    type="password"
                    value={values.repeat_password}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    autoComplete="current-password"
                    placeholder="********"
                    required
                    className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 px-4"/>
                {errors.repeat_password && touched.repeat_password ? (
                    <div className="text-red text-sm">{errors.repeat_password}</div>) : null}
            </div>

            <div>
                <Button title={t('button.change_password')} onClick={handleSubmit}/>
            </div>
        </div>)
        }
    </Formik>
}

export default AccountModal