export default {
    "label": {
        "email": "Электронная почта",
        "password": "Пароль",
        "need_help": "Нужна помощь? Свяжитесь с нами по телефону или электронной почте.",
        "cart_total": "Сумма корзины",
        "back_to_cart": "Вернуться в корзину",
        "choose_address": "Выберите адрес",
        "choose_payment_method": "Выберите способ оплаты",
        "cart_is_empty": "Ваша корзина пуста",
        "company_name": "Название компании",
        "delivery_date": "Дата доставки",
        "manager": "Менеджер",
        "order": "Заказ",
        "not_found": "Товар не найден",
        "total": "Итого",
        "created_at": "Создано",
        "no_addresses": "Нет сохраненных адресов",
        "comment": "Комментарий",
        "tin": "ИНН",
        "invoice_address": "Адрес счета",
        "phone": "Телефон",
        "search_product": "Найти продукт",
        "delivery_price": "Цена доставки",
        "delivery_time_note": "Это не конкретное время доставки, а период, когда ресторан принимает доставки, например, часы его работы. Пожалуйста, выберите минимум 4-часовой промежуток.",
        "net": "Нетто",
        "gross": "Брутто",
        "vat": "НДС",
        "delivery_address": "Адрес доставки"
    },
    "button": {
        "login": "Войти",
        "checkout": "Оформить заказ",
        "place_order": "Разместить заказ",
        "sign_up": "Зарегистрироваться",
        "catalog": "Каталог",
        "change_password": "Изменить пароль",
        "company_information": "Информация о компании",
        "addresses": "Адреса",
        "add_new_address": "Добавить новый адрес",
        "save": "Сохранить"
    },
    "title": {
        "dont_have_account": "Нет аккаунта?",
        "sign_up_now": "Зарегистрируйтесь сейчас",
        "need_help": "Нужна помощь? Свяжитесь с нами по телефону или электронной почте.",
        "already_have_account": "Уже есть аккаунт?",
        "login_in": "Вход",
        "thank_you_for_order": "Спасибо за ваш заказ!",
        "order_id_is": "Номер вашего заказа",
        "you_will_receive_invoice": "Вы скоро получите счет на зарегистрированный адрес электронной почты.",
        "no_orders_yet": "У вас еще нет заказов",
        "free_delivery_sum": "Бесплатная доставка доступна для заказов свыше {{sum}} {{currency}}."
    },
    "error": {
        "validation": {
            "invalid_email": "Недействительный адрес электронной почты",
            "invalid_password": "Неверный пароль",
            "required": "Обязательно для заполнения",
            "password_too_short": "Пароль слишком короткий - минимум 8 символов.",
            "password_latin": "Пароль может содержать только латинские буквы",
            "select_delivery_date": "Выберите дату доставки",
            "select_address": "Выберите адрес",
            "select_payment_method": "Выберите способ оплаты",
            "passwords_do_not_match": "Пароли не совпадают",
            "city_required": "Город обязателен",
            "street_required": "Улица обязательна",
            "street_255": "Улица должна содержать не более 255 символов",
            "state_255": "Регион должен содержать не более 255 символов",
            "postal_required": "Почтовый индекс обязателен",
            "postal_255": "Почтовый индекс должен содержать не более 20 символов",
            "country_required": "Страна обязательна",
            "country_255": "Страна должна содержать не более 100 символов",
            "no_address_available": "Нет адресов доступных для выбора. Пожалуйста, добавьте адрес или проверьте выбранный город.",
            "delivery_time_start_required": "Необходимо указать время начала доставки",
            "delivery_time_end_required": "Необходимо указать время окончания доставки",
        },
        "message": {
            "incorrect_current_password": "Неверный текущий пароль",
            "something_went_wrong": "К сожалению, что-то пошло не так. Попробуйте позже или свяжитесь с нами по телефону или электронной почте.",
            "no_user_found": "Активный аккаунт с указанными данными не найден. Пожалуйста, подтвердите ваш email, чтобы активировать аккаунт."
        }
    },
    "field": {
        "product": "Товар",
        "price": "Цена",
        "total": "Итого",
        "qty": "Количество",
        "name": "Название",
        "image": "Изображение",
        "package": "Упаковка",
        "weight": "Вес",
        "country": "Страна",
        "repeat_password": "Повторите пароль",
        "current_password": "Текущий пароль",
        "new_password": "Новый пароль",
        "street": "Улица",
        "postal_code": "Почтовый индекс",
        "composition": "Состав",
        "features": "Характеристики",
        "sku": "Артикул",
        "net": "Нетто",
        "gross": "Брутто",
        "vat": "Ндс",
        "delivery_time": "Время приема доставок",
        "from": "от",
        "to": "до",
        "tin": "ИНН",
        "invoice_address": "Адрес счета",
    },
    "header": {
        "account": "Аккаунт",
        "logout": "Выйти",
        "orders": "Заказы"
    },
    "message": {
        "password_updated": "Пароль обновлен",
        "updated": "Обновлено",
        "saved": "Сохранено",
        "email_confirmed": "Ваш email успешно подтверждён! Теперь вы можете получить доступ к порталу и начать его использовать. Спасибо, что выбрали наш сервис!",
        "confirm_email": "Пожалуйста, подтвердите ваш email, чтобы начать пользоваться порталом."
    }
}
